import React, { Component } from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import "./App.scss";
const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Login"));

class App extends Component {
  render() {
    const { currentUser } = this.props;
    console.log("TCL: App -> render -> currentUser", currentUser);
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            {currentUser ? (
              <Route
                path="/"
                name="Home"
                render={props => <DefaultLayout {...props} />}
              />
            ) : (
              <React.Fragment>
                <Route
                  exact
                  path="/"
                  name="Login Page"
                  render={props => <Login {...props} />}
                />
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  render={props => <Login {...props} />}
                />
                <Redirect from={window.location.pathname} to="/login" />
              </React.Fragment>
            )}
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser
});

export default connect(
  mapStateToProps,
  null
)(App);
