import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import localForage from "localforage";
import createEncryptor from "redux-persist-transform-encrypt";
import rootReducer from "./reducers";

const encryptor = createEncryptor({
  secretKey: "哈菲兹真主الله الحافظKT-mD=E@E$Bw알라하피즈AE&+shL9YUT4yQU!Ux8h",
  onError: function(error) {
    // Handle the error.
  }
});
const persistConfig = {
  key: "auth",
  storage: localForage,
  transforms: [encryptor]
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  let store = createStore(persistedReducer, applyMiddleware(thunk));
  let persistor = persistStore(store);
  return { store, persistor };
};
