import * as types from "../actions/types";
import * as localStorageProvider from "../cache/AsyncStorageProvider";

const initialState = {
  currentUser: null,
  error: null
};
// localStorageProvider.getItem("@CurrentUser") ||
const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return { ...state, currentUser: action.payload };
    case types.LOGIN_FAIL:
      return {
        ...state,
        error: action.payload
      };
    case types.LOGIN_RESET_ERROR:
      return {
        ...state,
        error: null
      };
    case types.LOGOUT:
      return { ...state, currentUser: null };

    default:
      return state;
  }
};
export default AuthReducer;
